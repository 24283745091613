import { Finger, FingerCurl, FingerDirection } from '../fingerDescription.js';
import GestureDescription from '../gestureDescription.js';
import thumbsUpDescription from "./thumbsUp.js";

// describe fingerSplayed gesture 🖐
const fingersSplayedDescription = new GestureDescription('fingers_splayed');

// all fingers:
// - curl: none (must)
// - direction vertical up (best)
// - direction diagonal up left / right (acceptable)
for(let finger of [Finger.Thumb, Finger.Index, Finger.Middle, Finger.Ring, Finger.Pinky]) {
    fingersSplayedDescription.addCurl(finger, FingerCurl.NoCurl, 1.0);
    fingersSplayedDescription.addDirection(finger, FingerDirection.VerticalUp, 1.0);
    fingersSplayedDescription.addDirection(finger, FingerDirection.DiagonalUpLeft, 0.95);
    fingersSplayedDescription.addDirection(finger, FingerDirection.DiagonalUpRight, 0.95);

    // Tank
    fingersSplayedDescription.addCurl(finger, FingerCurl.FullCurl, -10.0);
    fingersSplayedDescription.addCurl(finger, FingerCurl.HalfCurl, -10.0);
}

export default fingersSplayedDescription;