import * as bodyPix from '@tensorflow-models/body-pix';
import * as tf from '@tensorflow/tfjs';
import { Hands } from "@mediapipe/hands";
import BezierEasing from "bezier-easing";

import {
    midpointBetween2Coords,
    intersectionProportion,
    averageXY,
    average,
    round,
    roundXY, distanceBetween2Coords, angleBetweenACandAB
} from "@/js/helpers/geometry";

const swayEasing = BezierEasing(0.42,0,0.58,1);
let topPropellerFrame = 'top0.png';
let backPropellerFrame = 'back0.png';

let mask;
let x1; // top left of segment
let y1; // top left of segment
let x2; // bottom right of segment
let y2; // bottom right of segment
let headRatio;
let base;
let canvasWidth = 640;
let canvasHeight = 480;

const history = {
    shoulderMidpoint: [],
    yaw: [],
    nose: [],
    leftEye: [],
    rightEye: [],
    bodyAngle: [],
    x: []
}

const clearHistory = () => {
    history.nose = [];
    history.bodyAngle = [];
    history.rightEye = [];
    history.leftEye = [];
    history.shoulderMidpoint = [];
    history.yaw = [];
}

const updateHistory = (arr, newItem, maxLength = 1) => {
    if (typeof newItem !== 'number' && typeof newItem !== 'object') {
        return
    }
    if (arr.length >= maxLength) {
        arr.shift();
    }

    arr.push(newItem);
};

export const createSegmenter = async () => {

    let options = {
        architecture: 'MobileNetV1',
        outputStride: 8,
        quantBytes: 4,
        multiplier: 1.01,
        //modelUrl: '/tf-model/model-stride16.json'
    }
    // bodyPix.load(options)

    return await bodyPix.load();
}

export const renderSegmentation = async (segmenter, videoFeed, canvasWidth, canvasHeight, ctx, isActive, nellieWidth, nellieX, nellieY, appState) => {
    let state = 0;
    let segmentation = null;

    if (segmenter != null) {
        try {
            segmentation = await segmenter.segmentPersonParts(videoFeed, {
                flipHorizontal: true,
                internalResolution: 'full',
                segmentationThreshold: 0.5,
                maxDetections: 5,
            });
        } catch (error) {
            segmenter = null;
            console.log(error);
        }
    }

    const score = appState === 'download_photo' ? 0.35 : 0.5;
    let people = segmentation.allPoses.filter((pose) => pose.score > score);

    if (segmentation && people.length > 0) {
        const distFromMiddle = people.map((person, index) => ({
            dist: Math.abs((640 / 2) - person.keypoints[0].position.x),
            index: index,
        }));

        let closestPersonIndex = distFromMiddle.sort(sortByDist)[0].index;
        let closestPerson = people[closestPersonIndex];

        state = 1;

        let nose = closestPerson.keypoints.filter((_keypoint) => _keypoint.part === 'nose').shift();
        let leftEye = closestPerson.keypoints.filter((_keypoint) => _keypoint.part === 'leftEye').shift();
        let rightEye = closestPerson.keypoints.filter((_keypoint) => _keypoint.part === 'rightEye').shift();
        let leftHip = closestPerson.keypoints.filter((_keypoint) => _keypoint.part === 'leftHip').shift();
        let rightHip = closestPerson.keypoints.filter((_keypoint) => _keypoint.part === 'rightHip').shift();
        let leftShoulder = closestPerson.keypoints.filter((_keypoint) => _keypoint.part === 'leftShoulder').shift();
        let rightShoulder = closestPerson.keypoints.filter((_keypoint) => _keypoint.part === 'rightShoulder').shift();

        if (nose.score > 0.9) {
        //if (nose.score > 0.9 && leftHip.score > 0.2 && rightHip.score > 0.2) {
            state = 2;
        }

        if (isActive) {
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            ctx.globalCompositeOperation = 'source-over';

            base = calculatePositionsAndAngles(canvasWidth, canvasHeight, nellieWidth, leftHip, rightHip, leftShoulder, rightShoulder, leftEye, rightEye, nose, nellieX, nellieY);

            // create mask from binary
            await createMaskFromBinaryMask(segmentation, nose);
            let renderedHead = await renderHead(ctx, nellieWidth, videoFeed, base, nellieX, appState);

            return {
                state: state,
                head: base.head,
                helmetX: nellieX + renderedHead.xOffset,
                helmetY: (base.y + renderedHead.yOffset),
                bodyAngle: base.bodyAngle,
                shoulderMidpoint: base.shoulderMidpoint,
                blurredMask: renderedHead.blurredMask,
                headRatio: renderedHead.headRatio,
                x: renderedHead.x,
                y: renderedHead.y
            }
        }

    }

    // 0 = idle
    // 1 = not in view
    // 2 = ready
    return { state: state };
}

const renderImageDataToOffScreenCanvas = async (image) => {
    let canvas = document.createElement('canvas');
    await renderImageDataToCanvas(image, canvas);
    return canvas;
}

const renderImageDataToCanvas = async (image, canvas) => {
    canvas.width = image.width;
    canvas.height = image.height;
    let ctx = canvas.getContext('2d', { willReadFrequently: true });
    ctx.putImageData(image, 0, 0);
}

const drawAndBlurImageOnOffScreenCanvas = async (image, blurAmount, video, offset, canvasWidth, canvasHeight) => {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d', { willReadFrequently: true })

    await drawAndBlurImageOnCanvas(image, blurAmount, canvas);

    ctx.globalCompositeOperation = 'source-in';
    ctx.filter = "brightness(100%) saturate(0.8) hue-rotate(10deg) sepia(10%) contrast(110%)";
    ctx.drawImage(video, -offset.x + (blurAmount * 2), -offset.y + (blurAmount * 2), canvasWidth, canvasHeight);

    return canvas;
}

const drawAndBlurImageOnCanvas = async (image, blurAmount, canvas) => {
    let ctx = canvas.getContext('2d', { willReadFrequently: true });
    canvas.width = image.width + (blurAmount * 4);
    canvas.height = image.height + (blurAmount * 4);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.filter = "blur(" + blurAmount + "px)";
    ctx.drawImage(image, (blurAmount * 2), (blurAmount * 2), image.width, image.height);
    ctx.restore();
}

const createMaskFromBinaryMask = async (segmentation, nose) => {

    // convert left and right to just 1's
    let headSegmentation = segmentation.data.map((_part) => {
        if (_part === 0 || _part === 1) {
            return 1;
        }
        return -1;
    })


    let head = Array.from(headSegmentation); // convert from int32arr
    let x = Math.floor(nose.position.x);
    let y = Math.floor(nose.position.y);


    x1 = 0; // top left canvas
    x2 = canvasWidth;
    y1 = 0; // top left canvas
    y2 = canvasHeight;


    // Reduce canvas width and height if possible
    // go right
    for (let i = x; i <= canvasWidth; i++) {
        let w = true;
        for (let j = 0; j < canvasHeight; j++) {
            if (head[i + (j * canvasWidth)] === 1) {
                w = false;
                break;
            }
        }
        if (w) {
            x2 = i;
            break;
        }
    }


    //go left
    for (let i = x; i >= 0; i--) {
        let w = true;
        for (let j = 0; j < canvasHeight; j++) {
            if (head[i + (j * canvasWidth)] === 1) {
                w = false;
                break;
            }
        }
        if (w) {
            x1 = i;
            break;
        }
    }


    // go down
    for (let i = y; i <= canvasHeight; i++) {
        let w = true;
        for (let j = 0; j < (x2 - x1); j++) {
            if (head[(j + x1) + (i * canvasWidth)] === 1) {
                w = false;
                break;
            }
        }
        if (w) {
            y2 = i;
            break;
        }
    }


    // go up
    for (let i = y; i >= 0; i--) {
        let w = true;
        for (let j = 0; j < (x2 - x1); j++) {
            if (head[(j + x1) + (i * canvasWidth)] === 1) {
                w = false;
                break;
            }
        }
        if (w) {
            y1 = i;
            break;
        }
    }

    segmentation.data = [];

    // get crop
    for (let i = 0; i < (y2 - y1); i++) {
        for (let j = 0; j < (x2 - x1); j++) {
            segmentation.data.push(head[((y1 + i) * canvasWidth) + (x1 + j)]);
        }
    }

    segmentation.height = (y2 - y1);
    segmentation.width = (x2 - x1);

    //console.log(segmentation.width);

    if (segmentation.width > 0 && segmentation.height > 0) {
        let foregroundColor = {r: 0, g: 0, b: 0, a: 255};
        let backgroundColor = {r: 0, g: 0, b: 0, a: 0};
        let binaryMask = await bodyPix.toMask(segmentation, foregroundColor, backgroundColor, false);
        mask = await renderImageDataToOffScreenCanvas(binaryMask);

    }
}
const renderHead = async (ctx, nellieWidth, videoFeed, base, nellieX, appState) => {
    // use global canvasWidth and global canvasHeight vars

    // mask with video feed
    let blurredMask = await drawAndBlurImageOnOffScreenCanvas(mask, 5, videoFeed, {
        x: x1,
        y: y1
    }, canvasWidth, canvasHeight);

    let divisor = blurredMask.height > blurredMask.width ? blurredMask.height : blurredMask.width

    headRatio = (base.w * 0.25) / divisor;
    headRatio -= Math.abs(base.head.roll * 0.1 * headRatio) // keep head size consistent when you tilt your head

    let x = base.x - ((Math.floor(base.shoulderMidpoint.x) - x1) * headRatio);
    let y = base.y - ((Math.floor(base.shoulderMidpoint.y) - y1) * headRatio);

    updateHistory(history.x, x, 2);

    const personHasShiftedSignificantly = history.x.length > 1 && Math.abs(history.x[0] - history.x[1]) > 100;

    let nellieXShift = blurredMask.width > blurredMask.height ? blurredMask.height : blurredMask.width;
    nellieXShift = Math.floor(nellieXShift * headRatio * (0.7));


    if (personHasShiftedSignificantly) {
        clearHistory();
        x = (nellieX - nellieXShift);
    } else {
        if (appState === 'active') {
            ctx.drawImage(blurredMask, 0, 0, blurredMask.width, blurredMask.height, x, y, blurredMask.width * headRatio, blurredMask.height * headRatio);
        }
    }

    // tilting head left seems to extend the helmet further
     let xAdjustment = 0
    // if (base.head.roll > 0) {
    //     xAdjustment = Math.abs(base.head.roll * 40)
    // }

    return {
        blurredMask: blurredMask,
        headRatio: headRatio,
        xOffset: -(x - (nellieX - nellieXShift)) - xAdjustment, // 0.1346
        yOffset: y - nellieWidth - (nellieWidth * 0.19), // 0.1730,
        x,
        y
    };
}

const calculateYaw = (pointA, pointB, intersectionPoint) => {
    const intersectionPerc = intersectionPoint && pointA && pointB ? round(intersectionProportion(pointA, pointB, intersectionPoint), 0) : 0
    return round(2 * intersectionPerc - 1, 0); // fix between -1 and 1
}
const calculatePositionsAndAngles = (canvasWidth, canvasHeight, nellieWidth, leftHip, rightHip, leftShoulder, rightShoulder, leftEye, rightEye, nose, nellieX, nellieY) => {

    let nellieHeight = nellieWidth;
    let nellieXPosition = nellieX - (nellieWidth * 0.038);
    let nellieYPosition = nellieY - (nellieHeight * 0.37);

    let hipMidpoint = midpointBetween2Coords(leftHip.position.x, leftHip.position.y, rightHip.position.x, rightHip.position.y);
    let shoulderMidpoint = midpointBetween2Coords(leftShoulder.position.x, leftShoulder.position.y, rightShoulder.position.x, rightShoulder.position.y);

    // track values
    const maxArrItems = 5;

    updateHistory(history.shoulderMidpoint, shoulderMidpoint, 1);
    updateHistory(history.leftEye, roundXY(leftEye.position, 0), maxArrItems);
    updateHistory(history.rightEye, roundXY(rightEye.position,0), maxArrItems);
    updateHistory(history.nose, roundXY(nose.position,0), maxArrItems);

    // generate avg
    const roundTo = 0;
    const avgShoulderMidpoint = averageXY(history.shoulderMidpoint, 2);
    const avgLeftEye = averageXY(history.leftEye, roundTo);
    const avgRightEye = averageXY(history.rightEye, roundTo);
    const avgNose = averageXY(history.nose, roundTo);

    shoulderMidpoint = avgShoulderMidpoint || shoulderMidpoint

    let distY = (hipMidpoint.y - shoulderMidpoint.y);
    let distX = (hipMidpoint.x - shoulderMidpoint.x);

    let bodyAngle = Math.atan2(distY, distX) - (Math.PI / 2);
    updateHistory(history.bodyAngle, bodyAngle, 3);

    distY = (avgLeftEye.y - avgRightEye.y);
    distX = (avgLeftEye.x - avgRightEye.x);

    let yaw = calculateYaw(avgLeftEye, avgRightEye, avgNose);

    // cap off the yaw when we're looking too far to the left and right
    if (Math.abs(yaw) > 0.75) {
        const lookingLeft = yaw < 0
        yaw = lookingLeft ? -0.8 : 0.8

        if ((!rightEye || rightEye.score < 0.5) && leftEye) yaw = 0.8
        if ((!leftEye || leftEye.score < 0.5) && rightEye) yaw = -0.8
        if(!leftEye && !rightEye) yaw = 0
    }

    updateHistory(history.yaw, yaw,2);

    const avgYaw = average(history.yaw);
    const avgBody = average(history.bodyAngle);

    const eyeMidPoint = midpointBetween2Coords(avgLeftEye.x, avgLeftEye.y, avgRightEye.x, avgRightEye.y);
    const dist = (distanceBetween2Coords(avgNose.x, eyeMidPoint.x, avgNose.y, eyeMidPoint.y));

    let pitch = 0
    if (dist < 20) pitch = -0.2;
    if (dist > 27) pitch = 0.2;

    const head = {
        yaw: avgYaw,
        roll: round(Math.atan2(distY, distX), 0.1),
        pitch: 0
    }

    return {
        x: nellieXPosition,
        y: nellieYPosition,
        h: nellieHeight, w: nellieWidth,
        bodyAngle: avgBody,
        shoulderMidpoint: shoulderMidpoint,
        head: head,
    };
}

export const initHands = () => {
    return new Hands({locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/hands/${file}`;
    }});
}

export const nellieSway = (swayDirection, swayEnd, swayStartTime, swayActive, swayDuration) => {

    let swayElapsed = Date.now() - swayStartTime;

    if (swayElapsed < swayDuration) {
        let swayPos = swayEnd * swayEasing(swayElapsed / swayDuration);
        let swayBuffer = swayEnd / 2;
        if (swayDirection === 'top') {
            swayPos = 0 - swayBuffer + swayPos;
        } else {
            swayPos = swayBuffer - swayPos;
        }
        return { active: true, offset: swayPos };
    } else {
        (swayDirection === 'top') ? swayDirection = 'bottom' : swayDirection = 'top';
        return { active: false, direction: swayDirection };
    }

}

export const nelliePropeller = (topStartTime) => {
    let topElapsed = Date.now() - topStartTime;
    let duration = 1250;
    if (topElapsed < duration) {
        let frame = Math.floor(topElapsed / (duration/7));
        switch (frame) {
            case 1:
                topPropellerFrame = 'top1.png';
                backPropellerFrame = 'back1.png';
                break;
            case 2:
                topPropellerFrame = 'top2.png';
                backPropellerFrame = 'back2.png';
                break;
            case 3:
                topPropellerFrame = 'top3.png';
                backPropellerFrame = 'back3.png';
                break;
            case 4:
                topPropellerFrame = 'top4.png';
                backPropellerFrame = 'back4.png';
                break;
            case 5:
                topPropellerFrame = 'top5.png';
                backPropellerFrame = 'back5.png';
                break;
            case 6:
                topPropellerFrame = 'top6.png';
                backPropellerFrame = 'back6.png';
                break;
            default:
                topPropellerFrame = 'top0.png';
                backPropellerFrame = 'back0.png';

        }
        return { reset: false, topFrame: topPropellerFrame, backFrame: backPropellerFrame };
    } else {
        topPropellerFrame = 'top6.png';
        backPropellerFrame = 'back6.png';
        return { reset: true, topFrame: topPropellerFrame, backFrame: backPropellerFrame };
    }
}

const sortByDist = ( a, b ) => {
    if ( a.dist < b.dist ){
        return -1;
    }
    if ( a.dist > b.dist ){
        return 1;
    }
    return 0;
}