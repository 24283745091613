<template>
    <div class="fixed flex items-center justify-center inset-0 z-40">
        <div class="fixed flex items-center justify-center inset-0 mt-[145px]">
            <div ref="nellieContainer" class="transition-all duration-[100ms] absolute z-30 top-[-1000px] w-[1620px] h-[1620px] left-[-830px]" :style="`transform: translateX(${nellieX}px) translateY(${nellieOffsetY + nellieY}px)`">
                <img ref="propellerFrameTop0" :class="topPropellerFrame === 'top0.png' ? 'visible' : 'invisible'" src="/img/top0.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
                <img ref="propellerFrameTop1" :class="topPropellerFrame === 'top1.png' ? 'visible' : 'invisible'" src="/img/top1.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
                <img ref="propellerFrameTop2" :class="topPropellerFrame === 'top2.png' ? 'visible' : 'invisible'" src="/img/top2.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
                <img ref="propellerFrameTop3" :class="topPropellerFrame === 'top3.png' ? 'visible' : 'invisible'" src="/img/top3.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
                <img ref="propellerFrameTop4" :class="topPropellerFrame === 'top4.png' ? 'visible' : 'invisible'" src="/img/top4.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
                <img ref="propellerFrameTop5" :class="topPropellerFrame === 'top5.png' ? 'visible' : 'invisible'" src="/img/top5.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
                <img ref="propellerFrameTop6" :class="topPropellerFrame === 'top6.png' ? 'visible' : 'invisible'" src="/img/top6.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
                <img ref="propellerFrameBack0" :class="backPropellerFrame === 'back0.png' ? 'visible' : 'invisible'" src="/img/back0.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
                <img ref="propellerFrameBack1" :class="backPropellerFrame === 'back1.png' ? 'visible' : 'invisible'" src="/img/back1.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
                <img ref="propellerFrameBack2" :class="backPropellerFrame === 'back2.png' ? 'visible' : 'invisible'" src="/img/back2.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
                <img ref="propellerFrameBack3" :class="backPropellerFrame === 'back3.png' ? 'visible' : 'invisible'" src="/img/back3.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
                <img ref="propellerFrameBack4" :class="backPropellerFrame === 'back4.png' ? 'visible' : 'invisible'" src="/img/back4.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
                <img ref="propellerFrameBack5" :class="backPropellerFrame === 'back5.png' ? 'visible' : 'invisible'" src="/img/back5.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
                <img ref="propellerFrameBack6" :class="backPropellerFrame === 'back6.png' ? 'visible' : 'invisible'" src="/img/back6.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
                <img ref="propeller" src="/img/propeller.png" class="transition-all duration-[100ms] absolute z-20 top-0 w-[1620px] h-[1620px] left-0" />
                <img ref="nellie" src="/img/nellie.png" class="transition-all duration-[100ms] absolute z-50 top-0 w-[1620px] h-[1620px] left-0" />
            </div>
        </div>

        <Transition
            enter-active-class="transition-all duration-700 ease-out-in"
            leave-active-class="transition-all duration-150 ease-out-in"
            enter-from-class="opacity-0 -translate-y-8"
            leave-to-class="opacity-0"
            enter-to-class="opacity-100 translate-y-0"
            leave-from-class="opacity-100"
        >
            <img v-show="showLogo" alt="Macallan logo" class="w-[210px] absolute top-[5.8rem]" src="/img/logos/007-mc-logo-dark.png">
        </Transition>

        <Transition
            enter-active-class="transition-opacity duration-300 ease-out-in"
            leave-active-class="transition-opacity duration-150 ease-out-in"
            enter-from-class="opacity-0"
            leave-to-class="opacity-0"
            enter-to-class="opacity-100"
            leave-from-class="opacity-100"
        >
            <p
                v-show="promptUserToImproveKeypoint"
                class="
                z-50 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                rounded-full bg-white/[0.8] text-[1.125rem] w-max h-[3.75rem] text-black
                font-medium flex justify-center items-center px-7"
            >Please stand on the floor markings.</p>
        </Transition>
    </div>
</template>

<script setup>
import {ref, watch, onMounted, onBeforeUnmount, defineProps } from "vue";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import { CustomEase } from "gsap/CustomEase.js";
import { CustomWiggle } from "gsap/CustomWiggle.js";
import { nelliePropeller, nellieSway } from "@/js/helpers";

gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(CustomWiggle);

const props = defineProps({
    userDetected: {
        type: Boolean
    },
    userNearlyInView: {
        type: Boolean,
        default: false
    }
})
const emit = defineEmits(['ready-to-detect', 'complete'])

const nellie = ref(null);
const nellieX = ref(-390);
const nellieY = ref(663);

const propeller = ref(null);

const swayDirection = ref('top');
const swayEnd = ref(14);
const swayStartTime = ref(null);
const swayActive = ref(true);
const swayDuration = ref(1000);

const nellieOffsetY = ref(0);

let animationFrame = null;
let doSway = false;

const propellerStartTime = ref(null);
const topPropellerFrame = ref('top0.png');
const backPropellerFrame = ref('back0.png');

const propellerFrameTop0 = ref(null);
const propellerFrameTop1 = ref(null);
const propellerFrameTop2 = ref(null);
const propellerFrameTop3 = ref(null);
const propellerFrameTop4 = ref(null);
const propellerFrameTop5 = ref(null);
const propellerFrameTop6 = ref(null);

const propellerFrameBack0 = ref(null);
const propellerFrameBack1 = ref(null);
const propellerFrameBack2 = ref(null);
const propellerFrameBack3 = ref(null);
const propellerFrameBack4 = ref(null);
const propellerFrameBack5 = ref(null);
const propellerFrameBack6 = ref(null);

const totalAssets = 14;
const loadedAssets = ref([]);

const isNellieOnScreen = ref(true);

const enterSceneFinished = ref(false);
const exitSceneStarted = ref(false);

const showLogo = ref(false);
const promptUserToImproveKeypoint = ref(false);

const nellieContainer = ref(null);

let inViewCounter = ref(0);

onMounted(() => {
    propellerFrameTop0.value.addEventListener("load", handleImage);
    propellerFrameTop1.value.addEventListener("load", handleImage);
    propellerFrameTop2.value.addEventListener("load", handleImage);
    propellerFrameTop3.value.addEventListener("load", handleImage);
    propellerFrameTop4.value.addEventListener("load", handleImage);
    propellerFrameTop5.value.addEventListener("load", handleImage);
    propellerFrameTop6.value.addEventListener("load", handleImage);
    propellerFrameBack0.value.addEventListener("load", handleImage);
    propellerFrameBack1.value.addEventListener("load", handleImage);
    propellerFrameBack2.value.addEventListener("load", handleImage);
    propellerFrameBack3.value.addEventListener("load", handleImage);
    propellerFrameBack4.value.addEventListener("load", handleImage);
    propellerFrameBack5.value.addEventListener("load", handleImage);
    propellerFrameBack6.value.addEventListener("load", handleImage);
})

onBeforeUnmount(() => {
    cancelAnimationFrame(animationFrame);
})

const enterScene = () => {
    let enterDuration = 2.5;

    CustomEase.create("enterPath", "M0,0,C0.045,0.089,0.171,0.762,0.416,0.762,0.538,0.762,0.741,1,0.9,1,0.942,1,1,1,1,1");
    CustomWiggle.create("enterWiggle", {wiggles: 3});

    gsap.to(nellieContainer.value, {
        motionPath: {
            path: "M -413 657 C -117 748 682 769 606 727 C 568 708 465 704 314 715 C 241 722 336 726 370 725",
        },
        duration: enterDuration,
        ease: 'enterPath',
        onComplete: enterComplete
    });

    gsap.to(nellieContainer.value, {duration: enterDuration, rotation: 15, ease: 'enterWiggle'});
    animationFrame = requestAnimationFrame(onFrame);
}

const exitScene = () => {
    exitSceneStarted.value = true;
    let exitDuration = 2.5;
    doSway = false;

    CustomEase.create("custom", "M0,0,C0.023,0.07,0.093,0.238,0.14,0.238,0.185,0.238,0.307,0.231,0.348,0.358,0.392,0.495,0.564,1,0.712,1,0.94,1,0.96,1,1,1");
    CustomWiggle.create("enterWiggle", {wiggles: 1});

    gsap.to(nellieContainer.value, {
        motionPath: {
            path: "M 291 729 C 348 757 682 769 629 718 C 513 643 247 659 -532 550",
        },
        duration: exitDuration,
        ease: 'custom',
        onComplete: exitComplete
    });

    gsap.to(nellieContainer.value, {duration: exitDuration, rotation: -30, ease: 'enterWiggle'});
}

const enterComplete = async () => {
    nellieX.value = 370;
    nellieY.value = 725;
    doSway = true;

    showLogo.value = true;

    setTimeout(() => {
        enterSceneFinished.value = true;
        emit('ready-to-detect');
    }, 2500)
}

const exitComplete = async () => {
    emit('complete');
}

const onFrame = async () => {
    if (props.userNearlyInView || props.userDetected) {
        inViewCounter.value++;
    }


    if (doSway) {
        if (swayStartTime.value == null) swayStartTime.value = Date.now();
        let sway = nellieSway(swayDirection.value, swayEnd.value, swayStartTime.value, swayActive.value, swayDuration.value);
        if (sway.active) {
            nellieOffsetY.value = sway.offset;
        } else {
            swayDirection.value = sway.direction;
        }
    }

    if (propellerStartTime.value == null) propellerStartTime.value = Date.now();
    let propeller = nelliePropeller(propellerStartTime.value);
    topPropellerFrame.value = propeller.topFrame;
    backPropellerFrame.value = propeller.backFrame;
    if (propeller.reset) {
        propellerStartTime.value = null;
    }

    animationFrame = requestAnimationFrame(onFrame);
}

const handleImage = (data) => {
    loadedAssets.value.push(data.target.src);
}

watch(swayDirection, () => {
    swayStartTime.value = null;
});

watch(loadedAssets, (newVal) => {
    if (newVal.length === totalAssets) {
        setTimeout(() => {
            enterScene();
        }, 1000)
    }
}, { deep: true });

watch(() => props.userDetected, (userDetected) => {
    if (userDetected && isNellieOnScreen.value && enterSceneFinished.value && !exitSceneStarted.value) {
        isNellieOnScreen.value = false;
        exitScene();
    }
})
watch(enterSceneFinished, (enterSceneDone) => {
    if (enterSceneDone && isNellieOnScreen.value && props.userDetected && !exitSceneStarted.value) {
        isNellieOnScreen.value = false;
        exitScene();
    }
})

watch(inViewCounter, count => {
    if (count > 25 && enterSceneFinished.value) {
        inViewCounter.value = 0;
        promptUserToImproveKeypoint.value = !props.userDetected;
    }
});

</script>
