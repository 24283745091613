<template>
    <teleport to="body">
        <div class="fixed flex items-center justify-center inset-0 z-50">
            <p
                class="transition-opacity rounded-full bg-white/[0.8] py-4 px-6 text-black"
                :class="showMessage ? 'opacity-100' : 'opacity-0'"
            >The camera is currently unavailable.</p>
        </div>
    </teleport>
</template>

<script setup>
import { onMounted, ref } from "vue";

const showMessage = ref(false);

onMounted(() => {
    setTimeout(() => {
        showMessage.value = true;
    }, 1000);
})
</script>