<template>
    <div class="rounded-full h-[3.75rem] text-black text-lg relative mb-5">
        <button class="transition-all duration-500 flex rounded-full w-full h-full text-black text-lg relative z-20"
        :class="[gestureInProgress ? 'bg-white' : 'bg-white/[0.8]', $attrs.class]"
        >
            <slot></slot>
        </button>
        <svg :width="$attrs.svgWidth" height="76" :viewBox="`0 0 ${$attrs.svgWidth} 76`" fill="none" xmlns="http://www.w3.org/2000/svg" class="absolute top-[-8px] left-[-8px] z-30">
            <rect ref="border" x="1" y="1" :width="$attrs.svgWidth - 2" height="74" rx="37" stroke="white" stroke-width="2"/>
        </svg>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
}
</script>

<script setup>
import {ref, watch, defineProps, defineEmits, onMounted} from "vue";
import gsap from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin.js";
gsap.registerPlugin(DrawSVGPlugin);

const emit = defineEmits(['complete']);

const props = defineProps({
    gestureInProgress: {
        type: Boolean,
        default: false
    }
});

const border = ref(null);
let tl = gsap.timeline({ paused: true });
let freeze = false;

const animComplete = () => {
    freeze = true;
    emit('complete');
}

onMounted(() => {
    tl.fromTo(border.value, {drawSVG: "0 0%"}, {duration: 1.5, drawSVG: "0% 100%", ease: "power3.in", onComplete: animComplete});
})

watch(() => props.gestureInProgress, (newVal) => {
    if (freeze) return;
    
    if (newVal) {
        tl.timeScale(1).play();
    } else {
        tl.timeScale(1).reverse();
    }

})

</script>