export default function useAnalytics()
{

    const captureActivated = () => {
        fathomTrackEvent('IYJLNNUY');
    }

    const captureHappyWithPhoto = () => {
        fathomTrackEvent('LCKUEJW4');
    }

    const captureNotOver18 = () => {
        fathomTrackEvent('EV0IGV1W');
    }

    const captureOver18 = () => {
        fathomTrackEvent('WTAPZCKH');
    }

    const captureTakePhoto = () => {
        fathomTrackEvent('Z82UUBFM');
    }

    const captureUnhappyWithPhoto = () => {
        fathomTrackEvent('VPAWEQU3');
    }

    const fathomTrackEvent = (eventID = null) => {
        if(!eventID) {
            return;
        }

        window.fathom.trackGoal(eventID, 0);
    }

    return {
        captureActivated,
        captureHappyWithPhoto,
        captureNotOver18,
        captureOver18,
        captureTakePhoto,
        captureUnhappyWithPhoto,
    }
}
