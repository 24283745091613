import axios from 'axios';

export default function useShortUrl() {
    const generatedBrandedUrl = (url) => {
        return new Promise((resolve, reject) => {
            axios.post('https://api.rebrandly.com/v1/links', {
                destination: url,
                domain: {
                    fullName: 'the-macallan.blooq.com',
                },
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'apikey': process.env.VUE_APP_SHORT_URL_API_KEY,
                },
            }).then((response) => {
                resolve(response.data.shortUrl)
            });
        });
    }

    return {
        generatedBrandedUrl,
    };
}