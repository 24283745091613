import { Finger, FingerCurl, FingerDirection } from '../fingerDescription.js';
import GestureDescription from '../gestureDescription.js';
import thumbsDownDescription from "./thumbsDown.js";


// describe thumbs up gesture 👍
const thumbsUpDescription = new GestureDescription('thumbs_up');

// thumb:
// - not curled
// - vertical up (best) or diagonal up left / right
thumbsUpDescription.addCurl(Finger.Thumb, FingerCurl.NoCurl, 1.0);
thumbsUpDescription.addDirection(Finger.Thumb, FingerDirection.VerticalUp, 1.0);
thumbsUpDescription.addDirection(Finger.Thumb, FingerDirection.DiagonalUpLeft, 1.0);
thumbsUpDescription.addDirection(Finger.Thumb, FingerDirection.DiagonalUpRight, 1.0);
//Tank the score if it's not vertical / diagonal up
thumbsUpDescription.addDirection(Finger.Thumb, FingerDirection.VerticalDown, -10.0);
thumbsUpDescription.addDirection(Finger.Thumb, FingerDirection.DiagonalDownLeft, -10.0);
thumbsUpDescription.addDirection(Finger.Thumb, FingerDirection.DiagonalDownRight, -10.0);
thumbsUpDescription.addDirection(Finger.Thumb, FingerDirection.HorizontalLeft, -10.0);
thumbsUpDescription.addDirection(Finger.Thumb, FingerDirection.HorizontalRight, -10.0);
thumbsUpDescription.addCurl(Finger.Thumb, FingerCurl.FullCurl, -10.0);
thumbsUpDescription.addCurl(Finger.Thumb, FingerCurl.HalfCurl, -10.0);

// all other fingers:
// - curled
// - horizontal left or right
for(let finger of [Finger.Index, Finger.Middle, Finger.Ring, Finger.Pinky]) {
  thumbsUpDescription.addCurl(finger, FingerCurl.FullCurl, 1.0);
  thumbsUpDescription.addCurl(finger, FingerCurl.HalfCurl, 1.0);
  thumbsUpDescription.addDirection(finger, FingerDirection.HorizontalLeft, 1.0);
  thumbsUpDescription.addDirection(finger, FingerDirection.HorizontalRight, 1.0);
  thumbsUpDescription.addDirection(finger, FingerDirection.DiagonalUpLeft, 0.5);
  thumbsUpDescription.addDirection(finger, FingerDirection.DiagonalUpRight, 0.5);
  thumbsUpDescription.addDirection(finger, FingerDirection.DiagonalDownLeft, 0.5);
  thumbsUpDescription.addDirection(finger, FingerDirection.DiagonalDownRight, 0.5);
  // Tank
  thumbsUpDescription.addCurl(finger, FingerCurl.NoCurl, -10.0);
  thumbsUpDescription.addDirection(finger, FingerDirection.VerticalUp, -10.0);
  thumbsUpDescription.addDirection(finger, FingerDirection.VerticalDown, -10.0);
}

export default thumbsUpDescription;
