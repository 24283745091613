<template>
    <teleport to="body">
        <div class="fixed flex flex-col items-center justify-start inset-0 pt-[5.8rem] z-50 bg-black text-white">

            <img ref="logo" alt="Macallan logo" class="w-[210px] mb-40" src="/img/logos/007-mc-logo-light.png">

            <!-- Loading Icon -->
            <div class="flex justify-center items-center flex-wrap font-medium text-4xl mt-40" v-if="uploading">
                <p class="w-full text-center">Uploading</p>
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>

            <div v-else class="space-y-24 flex flex-col items-center min-h-[550px]">
                <div class="flex justify-center flex-col items-center">
                    <div ref="digital_text" class="flex font-digital text-8xl text-shadow w-[19rem]"><span>00:00:</span><span class="w-[45px] text-end">{{ tensText }}</span><span class="w-[46px] text-end">{{ unitsText }}</span></div>
                    <p ref="digital_subtext" class="uppercase text-center mb-2 text-lg">After 30 seconds this image will be <br>permanently deleted</p>
                </div>

                <div class="relative">


                    <svg v-if="showCorners" ref="corner1" class="absolute top-[9px] left-[187px] z-10" width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect id="corner1Rect" x="3.5" y="3.5" width="32" height="32" fill="none" stroke="white" stroke-width="7"/>
                    </svg>
                    <svg v-if="showCorners" ref="corner2" class="absolute top-[9px] left-[9px] z-10" width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect id="corner2Rect" x="3.5" y="3.5" width="32" height="32" fill="none" stroke="white" stroke-width="7"/>
                    </svg>
                    <svg v-if="showCorners" ref="corner3" class="absolute top-[187px] left-[9px] z-10" width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect id="corner3Rect" x="3.5" y="3.5" width="32" height="32" fill="none" stroke="white" stroke-width="7"/>
                    </svg>

<!--                    <img src="/dummy.png">-->

                    <canvas ref="qrcode" class="opacity-0" width="234" height="234"></canvas>
                    <p ref="download_text" class="uppercase font-medium text-center text-lg mt-8">Download and share</p>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script setup>
import {ref, onMounted, watch} from "vue";
import QRCode from "qrcode";
import { gsap } from 'gsap';
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin.js";

import useS3Upload from "@/composables/useS3Upload";
import useShortUrl from "@/composables/useShortUrl";

gsap.registerPlugin(DrawSVGPlugin);

const {
    uploadBlob,
    uploading,
} = useS3Upload();
const {
    generatedBrandedUrl,
} = useShortUrl();

const emit = defineEmits(['complete']);

const secondsText = ref('29');
const tensText = ref('3');
const unitsText = ref('0');
const props = defineProps({
    blobData: {
        type: [String, Blob],
        default() {
            const canvas = document.createElement('canvas');
            canvas.width = 205;
            canvas.height = 328;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = 'blue';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            const blob = canvas.toDataURL();
            canvas.remove();
            return blob;
        }
    }
});

const timer = ref(null);
const qrcode = ref(null);

let timeLeft = ref(30);
let timerId;

// el refs
const digital_text = ref(null);
const digital_subtext = ref(null);
const download_text = ref(null);
const logo = ref(null);
let tl = gsap.timeline({ paused: true });

const corner1 = ref(null);
const corner2 = ref(null);
const corner3 = ref(null);

const showCorners = ref(true);
const cornersComplete = ref(false);
const QRReady = ref(false);

const showQRCode = () => {
    gsap.fromTo(qrcode.value, { opacity: 0}, { opacity: 1, duration: 1 });

    setTimeout(() => {
        showCorners.value = false;
    }, 1000);

    timerId = setInterval(countdown, 1000);
}
const animComplete = () => {
    cornersComplete.value = true;
    if (QRReady.value) {
        showQRCode();
    }
}

onMounted(() => {
    const translateY = '-40px';
    const ease = 'power4';
    const duration = 0.6;

    tl.fromTo(logo.value,
        {
            opacity: 0.1,
            transform: `translateY(${translateY})`
        }, {
            opacity: 1,
            transform: 'translateY(0)',
            duration,
            ease
        });

    tl.fromTo(digital_text.value,
        {
            opacity: 0.1,
            transform: `translateY(${translateY})`
        }, {
            opacity: 1,
            transform: 'translateY(0)',
            duration,
            ease
        }, '<0.1')

    tl.fromTo(digital_subtext.value,
        {
            opacity: 0.1,
            transform: `translateY(${translateY})`
        }, {
            opacity: 1,
            transform: 'translateY(0)',
            duration,
            ease
        }, '<0.1');


    tl.fromTo("#corner1Rect", {drawSVG: "0 0%"}, {duration: 1, drawSVG: "0% 100%"}, '<0.1');
    tl.fromTo("#corner2Rect", {drawSVG: "0 0%"}, {duration: 1, drawSVG: "0% 100%"}, '<0.3');
    tl.fromTo("#corner3Rect", {drawSVG: "0 0%"}, {duration: 1, drawSVG: "0% 100%", onComplete: animComplete}, '<0.3');

    tl.fromTo(download_text.value,
        {
            opacity: 0
        }, {
            opacity: 1,
            duration,
            ease
        });

    tl.play();






    uploadBlob(props.blobData).then((response) => {
        const urlWithParams = new URL(process.env.VUE_APP_MACALLAN_DOWNLOADER_URL);
        //urlWithParams.searchParams.append('image', response.Location);
        generatedBrandedUrl(response.Location).then((response) => {
            const shortUrl = response.slice(23);
            urlWithParams.searchParams.append('i', shortUrl);
            // see - https://www.npmjs.com/package/qrcode
            QRCode.toCanvas(qrcode.value, urlWithParams.toString() , {
                width: 234,
                height: 234,
                margin: 0,
                color: {
                    dark: '#000000',
                    light: '#ffffff'
                }
            }).catch((err) => {
                // TODO: handle error
                console.error(err);
            }).finally(() => {
                QRReady.value = true;
                if (cornersComplete.value) {
                    showQRCode();
                }
            });
        }).catch((err) => {
            // TODO: handle error
            console.error(err);
        });
    }).catch((err) => {
        // TODO: handle error
        console.error(err);
    });
});

const countdown = () =>  {
    if (timeLeft.value === 0) {
        clearTimeout(timerId);
        emit('complete');
    } else {
        timeLeft.value--;
        setTensAndUnits(timeLeft.value)
    }
}

const setTensAndUnits = seconds => {
    const doubleDigits = seconds < 10 ? '0' + String(seconds) : String(seconds)
    tensText.value = doubleDigits[0];
    unitsText.value = doubleDigits[1];
}

</script>

<style scoped>
    .text-shadow {
        text-shadow: 0 0 8px #fff;
        /*text-shadow: 0 0 8px #fff, 0 0 20px #0b0b0b, 0 0 30px #626262, 0 0 40px #000;*/
    }

    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
</style>