import { FingerPoseEstimator } from "./fingerPoseEstimator.js";
import { Finger, FingerCurl, FingerDirection } from "./fingerDescription.js";
import ThumbsUpGesture from './gestures/thumbsUp.js';
import ThumbsDownGesture from './gestures/thumbsDown.js';
import FingersSplayedGesture from './gestures/fingersSplayed.js';

export class GestureEstimator {
    constructor() {
        this.estimator = new FingerPoseEstimator();
        this.gestures = [ThumbsUpGesture, FingersSplayedGesture, ThumbsDownGesture];
    }

    estimate(hands, minScore = 8) {
        let gestureScore = 0;
        let gestureFound = null;
        let handData = [];

        hands.forEach((landmarks) => {
            if (typeof landmarks === 'object') {
                // step 1: get estimations of curl / direction for each finger
                const est = this.estimator.estimate(landmarks);

                let poseData = [];
                for (let fingerIdx of Finger.all) {
                    poseData.push([
                        Finger.getName(fingerIdx),
                        FingerCurl.getName(est.curls[fingerIdx]),
                        FingerDirection.getName(est.directions[fingerIdx])
                    ]);
                }

                const scoresPerGesture = typeof minScore === 'object'
                // step 2: compare gesture description to each known gesture
                for (let gesture of this.gestures) {
                    let score = gesture.matchAgainst(est.curls, est.directions);
                    const minTargetScore = scoresPerGesture ? minScore[gesture.name] : minScore;
                    if (score >= minTargetScore && score > gestureScore) {
                        gestureFound = gesture.name;
                        gestureScore = score;
                    }
                }

                handData.push(poseData);
            }
        })

        return {
            handData: handData,
            gestureFound: gestureFound,
            gestureScore: gestureScore,
        };

    }

    highestScore(a, b) {
        if ( a.score < b.score ){
            return -1;
        }
        if ( a.score > b.score ){
            return 1;
        }
        return 0;
    }
}