import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';

let scene = new THREE.Scene();
let camera;
let renderer;

let helmet;
let occluder;
let mouthpiece;

let loadedModels = [];
let group = new THREE.Group();

let yRotation = 0;
let yPosition = 0.4;
let xRotation = 0;
let scale = 0.9;

export const createScene = (canvas) => {
    let aspectRatio = canvas.width / canvas.height;
    camera = new THREE.PerspectiveCamera(75, aspectRatio);

    const loader = new GLTFLoader();

    // load a resource
    loader.load(
        // resource URL
        '/helmet.gltf',
        // called when resource is loaded
        ( gltf ) => {
            const scale = 0.044;
            helmet = gltf.scene;
            helmet.scale.set(scale, scale, scale);
            helmet.rotation.y = 2 * Math.PI;
            createGroup('helmet');
        },
        // called when loading is in progresses
        function ( xhr ) {
            //console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
        },
        // called when loading has errors
        function ( error ) {
            //console.log( error );
        }
    );

    //load a resource
    loader.load(
        // resource URL
        '/occluder.gltf',
        // called when resource is loaded
        ( gltf ) => {
            const scale = 0.09;
            occluder = gltf.scene;
            occluder.scale.set(scale, scale, scale);
            occluder.position.y = -0.8;
            occluder.position.z = 1.2;
            occluder.renderOrder = -1;

            occluder.traverse( (child) => {
                if (child instanceof THREE.Mesh) {
                    child.material.colorWrite = false;
                }
            });

            occluder.rotation.y = 2 * Math.PI;

            createGroup('occulder');
        },
        // called when loading is in progresses
        function ( xhr ) {
            //console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
        },
        // called when loading has errors
        function ( error ) {
            //console.log( error );
        }
    );

    let textureFront = new THREE.TextureLoader().load('/mouthpiece_front.png');
    let textureSide = new THREE.TextureLoader().load('/mouthpiece_side.png');

    const geometry = new THREE.BoxGeometry(1, 0.748, 1);
    const material = [
        new THREE.MeshBasicMaterial({ color: 'white', transparent: true, opacity: 0, }),
        new THREE.MeshBasicMaterial({ map: textureSide, transparent: true, }),
        new THREE.MeshBasicMaterial({ color: 'white', transparent: true, opacity: 0, }),
        new THREE.MeshBasicMaterial({ color: 'white', transparent: true, opacity: 0, }),
        new THREE.MeshBasicMaterial({ map: textureFront, transparent: true, }),
        new THREE.MeshBasicMaterial({ color: 'white', transparent: true, opacity: 0, }),
    ];
    mouthpiece = new THREE.Mesh(geometry, material);
    mouthpiece.scale.set(0.75,0.75,0.75);
    mouthpiece.position.x = -0.4;
    mouthpiece.position.y = -1.1;
    mouthpiece.position.z = 0.5;
    mouthpiece.renderOrder = -2;

    const light = new THREE.AmbientLight( 0x404040, 8);
    camera.add(light);
    camera.position.z = 3; // move back 3 units

    scene.add(camera);

    renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
        canvas: canvas,
    })

    renderer.setSize(canvas.width, canvas.height);
    //renderer.setPixelRatio(window.devicePixelRatio);
}

export const renderScene = () => {

    // Animating the yaw rotation
    // new TWEEN.Tween(group.rotation)
    //     .to({ y: yRotation }, 50)
    //     .delay(10)
    //     .start()

    group.rotation.y = yRotation;
    group.rotation.x = xRotation;
    group.position.y = yPosition * scale;
    group.scale.set(scale,scale,scale);

    // TWEEN.update()


    renderer.render(scene, camera);
}

const createGroup = (name) => {
    loadedModels.push(name);
    if (loadedModels.length === 2) {
        group.add(mouthpiece);
        group.add(helmet);
        group.add(occluder);
        scene.add(group);
    }
}

export const setYRotation = (val) => {
    yRotation = val;
}
export const setXRotation = (val) => {
    xRotation = val;
}