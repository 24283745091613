export const distanceBetween2Coords = (x2, x1, y2, y1) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

export const midpointBetween2Coords = (x1, y1, x2, y2) => {
    return {
        x: (x1 + x2) / 2,
        y: (y1 + y2) / 2,
    }
}

export const intersectionProportion = (a, b, c, limitToBetween = false) =>  {
    var ab = {x: b.x - a.x, y: b.y - a.y};
    var ac = {x: c.x - a.x, y: c.y - a.y};
    var t = (ac.x * ab.x + ac.y * ab.y) / (ab.x * ab.x + ab.y * ab.y);

    if (limitToBetween) {
        if (t < 0) return null;
        if (t > 1) return null;
    }

    return t;
}

export const averageXY = (arr, roundTo = 1) => {
    let sum, length = 0

    length = arr.length
    if (length === 0) return

    sum = arr.reduce((rollingTtl,currentItem) => {
        return {
            x: rollingTtl.x + (currentItem.x / length),
            y: rollingTtl.y + (currentItem.y / length)
        }
    }, { x: 0, y: 0})

    return { x: round(sum.x, roundTo), y: round(sum.y, roundTo) }
}

export const average = (arr, roundTo = 0) => {
    let sum, length = 0
    const inverse = 1.0 / roundTo

    length = arr.length
    if (length === 0) return

    sum = arr.reduce((rollingTtl,currentItem) => {
        return rollingTtl + (currentItem / length)
    })

    return round(sum, roundTo)
}

const roundInt = (num, to = 1) => to === 0 ? num : Math.round(num / to) * to;

export  const round = (num, to = 0) => {

    if (typeof to !== 'number' || typeof num !== 'number') {
        // throw new Error(`Invalid args passed: num: ${num}, round to: ${to}`)
    }
    if (num === 0 || to === 0) return num

    const numDecimals = String(to).includes('.') ? String(to).length - 2 : 0
    const multiplier = 10 ** numDecimals

    const roundedVal = roundInt(num * multiplier, to * multiplier) / multiplier
    const final = String(roundedVal).includes('.') ? Number(roundedVal.toFixed(numDecimals)) : roundedVal
    
    return final
}

export const roundXY = (coord, to = 0) => {
    if ('position' in coord) {
        return {
            position: {
                x: round(coord.position.x, to),
                y: round(coord.position.y, to),
            }
        }
    } else {
        return {
            x: round(coord.x, to),
            y: round(coord.y, to)
        }
    }
}

const vectorFromTwoCoords = (a, b) => {
    return { x: b.x - a.x, y: b.y - a.y }
}
export const angleBetweenACandAB = (a, b, c, degrees = false) => {
    const ab = vectorFromTwoCoords(a, b);
    const ac = vectorFromTwoCoords(a, c);
    const aDotb = (ac.x * ab.x + ac.y * ab.y);
    const magA = Math.sqrt(ac.x ** 2 + ac.y ** 2);
    const magB = Math.sqrt(ab.x ** 2 + ab.y ** 2);

    const angle = Math.acos(aDotb / (magA * magB));
    return degrees ? angle * (180 / Math.PI) : angle
}