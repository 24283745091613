<template>
    <div class="fixed flex flex-col items-center justify-start text-center pt-[5.8rem] pb-[51px] inset-0 z-30 bg-black/[0.6] text-white">
        <img ref="logo" class="w-[210px]" src="/img/logos/007-mc-logo-light.png">
        <div class="mt-[15rem]">
            <div
                v-if="!(props.ageConfirmed && !props.isOver18)"
                class="flex flex-col items-center text-[24px]">
                <h2 ref="msg1" class="font-bold uppercase">Start Experience</h2>
                <p ref="msg2" class="font-medium mb-10">Use hand gestures to control</p>

                <div ref="btn1">
                    <BorderButton :gesture-in-progress="gesture === 'thumbs_up' && gestureInProgress ? true : false"
                                  class="w-[13.5rem]" :class="`justify-start items-center space-x-5 pl-6`" :svgWidth="232"
                                  @complete="$emit('user-action', 'is_over_18')"
                    >
                        <ThumbsUpIcon />
                        <span class="pt-px">I am over 18</span>
                    </BorderButton>
                </div>

                <div ref="btn2">
                    <BorderButton ref="btn2" :gesture-in-progress="gesture === 'thumbs_down' && gestureInProgress ? true : false"
                        class="w-[13.5rem]" :class="`justify-center items-center space-x-3 pt-px`" :svgWidth="232"
                        @complete="$emit('user-action', 'not_over_18')"
                    >
                        <ThumbsDownIcon class="mt-px" />
                        <span class="-mt-px">I am not over 18</span>
                    </BorderButton>
                </div>

            </div>
            <!-- Under 18 message-->
            <Transition
                enter-active-class="transition-opacity duration-300 ease-out-in"
                leave-active-class="transition-opacity duration-[750ms] ease-out-in"
                enter-from-class="opacity-0"
                leave-to-class="opacity-0"
                enter-to-class="opacity-100"
                leave-from-class="opacity-100"
            >
            <p
                v-if="props.ageConfirmed && !props.isOver18"
                class="flex justify-center items-center rounded-full mt-28 px-8 text-[1.125rem] w-max h-[3.75rem] bg-white/[0.8] text-black">Unfortunately you must be over 18 to interact with Little Nellie
            </p>
            </Transition>
        </div>
    </div>
</template>

<script setup>
import ThumbsUpIcon from "@/components/icons/ThumbsUpIcon.vue";
import ThumbsDownIcon from "@/components/icons/ThumbsDownIcon.vue";
import {defineProps, onMounted, ref, defineEmits} from "vue";
import { gsap } from 'gsap';
import BorderButton from "@/components/BorderButton.vue"

const props = defineProps({
    gesture: {
        type: String,
        default: ''
    },
    isOver18: {
        type: Boolean,
        default: false
    },
    ageConfirmed: {
        type: Boolean,
        default: false
    },
    show: {
        type: Boolean,
        default: false
    },
    gestureInProgress: {
        type: Boolean,
        default: false
    }
});

const logo = ref(null);
const msg1 = ref(null);
const msg2 = ref(null);
const btn1 = ref(null);
const btn2 = ref(null);
let t1 = null;

const exitMessage = ref(false);


onMounted(() => {
    exitMessage.value = false;
    const ease = 'power4';
    const translateY = '-40px';

    const duration = {
        msg: 0.8,
        btn: 0.6
    }

    // const ease = 'power4';
    t1 = gsap.timeline({
        paused: true
    });

    t1.fromTo(logo.value, {
        opacity: 0.1,
        transform: `translateY(${translateY})`,
    }, {
        opacity: 1,
        transform: 'translateY(0)',
        duration: 0.5,
        ease
    })

    t1.fromTo(msg1.value, {
        transform: `translateY(${translateY})`,
        opacity: 0,
    }, {
        opacity: 1,
        transform: "translateY(0)",
        duration: duration.msg,
        ease
    }, '<');

    t1.fromTo(msg2.value, {
        opacity: 0,
        transform: `translateY(${translateY})`
    }, {
        opacity: 1,
        transform: "translateY(0)",
        duration: duration.msg,
        ease
    }, '<0.05');

    t1.fromTo(btn1.value, {
        opacity: 0,
        transform: `translateY(${translateY})`
    }, {
        opacity: 1,
        transform: "translateY(0)",
        duration: duration.btn,
        ease
    }, '<0.1');

    t1.fromTo(btn2.value, {
        opacity: 0,
        transform: `translateY(${translateY})`
    }, {
        opacity: 1,
        transform: "translateY(0)",
        duration: duration.btn,
        ease
    }, "<0.1");

    t1.play()
})

defineEmits(['user-action']);

</script>