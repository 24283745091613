import { ref } from 'vue';
import S3 from 'aws-sdk/clients/s3';
import { v4 as uuidv4 } from 'uuid';

export default function useS3Upload() {
    const uploadStatus = ref(null);
    const uploadProgress = ref(0);

    const url = process.env.VUE_APP_AWS_S3_URL;
    const bucket = process.env.VUE_APP_AWS_S3_BUCKET;
    const region = process.env.VUE_APP_AWS_S3_REGION;
    const accessKeyId = process.env.VUE_APP_AWS_ACCESS_KEY_ID;
    const secretAccessKey = process.env.VUE_APP_AWS_SECRET_ACCESS_KEY;

    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {type: mimeString});
    }

    const uploadFile = async (file) => {
        const fileName = uuidv4()+'.jpg';
        return upload(file, {
            Bucket: bucket,
            Key: fileName,
            Body: file,
            ContentDisposition: 'attachment; filename='+fileName,
        });
    };

    const uploadBlob = (blob) => {
        const fileName = uuidv4()+'.jpg';
        return upload({
            Bucket: bucket,
            Key: fileName,
            Body: blob instanceof Blob ? blob : dataURItoBlob(blob),
            ContentType: 'image/jpeg',
            ContentEncoding: 'base64',
            ContentDisposition: 'attachment; filename='+fileName,
        });
    }

    const upload = (params) => {
        return new Promise((resolve, reject) => {
            const s3 = new S3({
                s3BucketEndpoint: true,
                endpoint: url,
                region,
                accessKeyId,
                secretAccessKey,
            })

            s3.upload(params, (err, data) => {
                if (err) {
                    uploadStatus.value = 'error';
                    reject(err);
                    return;
                }

                uploadStatus.value = 'success';
                resolve(data);
            }).on('httpUploadProgress', (progress) => {
                uploadProgress.value = Math.round((progress.loaded / progress.total) * 100);
            });

            s3.getObject()
        });
    }

    return {
        uploadStatus,
        uploadProgress,
        dataURItoBlob,
        upload,
        uploadFile,
        uploadBlob,
    };
}
