import { Finger, FingerCurl, FingerDirection } from '../fingerDescription.js';
import GestureDescription from '../gestureDescription.js';
import thumbsUpDescription from "./thumbsUp.js";

// describe thumbsDown gesture 👎🏿
const thumbsDownDescription = new GestureDescription('thumbs_down');

// thumb:
// - not curled
// - vertical down (best) or diagonal down left / right
thumbsDownDescription.addCurl(Finger.Thumb, FingerCurl.NoCurl, 1.0);
thumbsDownDescription.addDirection(Finger.Thumb, FingerDirection.VerticalDown, 1.0);
thumbsDownDescription.addDirection(Finger.Thumb, FingerDirection.DiagonalDownLeft, 1.0);
thumbsDownDescription.addDirection(Finger.Thumb, FingerDirection.DiagonalDownRight, 1.0);
//Tank the score if it's not vertical / diagonal down
thumbsDownDescription.addDirection(Finger.Thumb, FingerDirection.VerticalUp, -10.0);
thumbsDownDescription.addDirection(Finger.Thumb, FingerDirection.DiagonalUpLeft, -10.0);
thumbsDownDescription.addDirection(Finger.Thumb, FingerDirection.DiagonalUpRight, -10.0);
thumbsDownDescription.addDirection(Finger.Thumb, FingerDirection.HorizontalLeft, -10.0);
thumbsDownDescription.addDirection(Finger.Thumb, FingerDirection.HorizontalRight, -10.0);
thumbsDownDescription.addCurl(Finger.Thumb, FingerCurl.FullCurl, -10.0);
thumbsDownDescription.addCurl(Finger.Thumb, FingerCurl.HalfCurl, -10.0);

// all other fingers:
// - curled
// - horizontal left or right
for(let finger of [Finger.Index, Finger.Middle, Finger.Ring, Finger.Pinky]) {
    thumbsDownDescription.addCurl(finger, FingerCurl.FullCurl, 1.0);
    thumbsDownDescription.addCurl(finger, FingerCurl.HalfCurl, 1.0);
    thumbsDownDescription.addDirection(finger, FingerDirection.HorizontalLeft, 1.0);
    thumbsDownDescription.addDirection(finger, FingerDirection.HorizontalRight, 1.0);
    thumbsDownDescription.addDirection(finger, FingerDirection.DiagonalUpLeft, 0.5);
    thumbsDownDescription.addDirection(finger, FingerDirection.DiagonalUpRight, 0.5);
    thumbsDownDescription.addDirection(finger, FingerDirection.DiagonalDownLeft, 0.5);
    thumbsDownDescription.addDirection(finger, FingerDirection.DiagonalDownRight, 0.5);
    // Tank
    thumbsDownDescription.addCurl(finger, FingerCurl.NoCurl, -10.0);
    thumbsDownDescription.addDirection(finger, FingerDirection.VerticalUp, -10.0);
    thumbsDownDescription.addDirection(finger, FingerDirection.VerticalDown, -10.0);
}

export default thumbsDownDescription;
