<template>
    <CameraUnavailable v-if="cameraUnavailable || !cameras.length" />

    <!-- VIEW: Dormat/Idle   -->
    <IdleState v-if="app.isIdle || app.state === 'idle' || app.state === 'ready_to_detect'"
       :user-detected="user.is_in_view && user.has_good_keypoint_score"
       :user-nearly-in-view="user.is_in_view && !user.has_good_keypoint_score"
       @ready-to-detect="app.state='ready_to_detect'"
       @complete="app.state='legal_age_check'"
    />

    <!-- VIEW: Age Limit Check  -->
    <Transition
        enter-active-class="transition-opacity duration-300 ease-out-in"
        leave-active-class="transition-opacity duration-[750ms] ease-out-in"
        enter-from-class="opacity-0"
        leave-to-class="opacity-0"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
    >
        <ReadyState v-if="app.state === 'legal_age_check'" :is-over-18="user.is_over_18" :age-confirmed="user.confirmed_age" :gesture="currentGesture" :gesture-in-progress="gestureInProgress" @userAction="setUserAction"/>
    </Transition>

    <!-- VIEW: Interactive -->
    <video ref="videoFeed" class="hidden absolute top-0 left-0" width="640" height="480" autoplay playsinline></video>
    <canvas ref="canvas" v-show="userIsActive && !awaitingPhotoConfirmation && !app.gsapPlaying" class="absolute z-40 top-[-5px]" width="720" height="900" :style="`opacity: ${1 - (idleCounter / 10)}; transform: translateY(${nellieOffsetY}px) scaleX(-1)`" />
    <canvas v-show="userIsActive && !awaitingPhotoConfirmation && !app.showInitialFace" ref="threeCanvas" class="transition-all duration-[100ms] absolute z-40 top-[-5px] left-0" width="200" height="200"
            :style="`opacity: ${1 - (idleCounter / 10)}; transform-origin: ${headRotationOrigin}% 50%; transform: translateX(${helmetX - 100}px) translateY(${nellieOffsetY + helmetY - 100}px) rotate(${headAngle}rad)`" />

    <img src="/img/hills.png" class="absolute z-10 left-0 bottom-0 h-full w-full" />
    <template v-if="userIsActive && !awaitingPhotoConfirmation">
        <img ref="logoDark" class="absolute left-1/2 -translate-x-1/2 top-[5.8rem] w-[210px] z-[11]" src="/img/logos/007-mc-logo-dark.png" :style="`opacity: ${1 - (idleCounter / 10)};`">
        <div ref="nellieContainer" :class="nellieInFrontOfHills ? 'z-30' : 'z-0'"  class="transition-all duration-[100ms] absolute top-[-1000px] w-[1620px] h-[1620px] left-[-830px]" :style="`opacity: ${1 - (idleCounter / 10)}; transform: translateX(${nellieX}px) translateY(${nellieOffsetY + nellieY}px) rotate(${bodyAngle}rad)`">
            <img ref="propellerFrameTop0" :class="topPropellerFrame === 'top0.png' ? 'visible' : 'invisible'" src="/img/top0.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
            <img ref="propellerFrameTop1" :class="topPropellerFrame === 'top1.png' ? 'visible' : 'invisible'" src="/img/top1.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
            <img ref="propellerFrameTop2" :class="topPropellerFrame === 'top2.png' ? 'visible' : 'invisible'" src="/img/top2.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
            <img ref="propellerFrameTop3" :class="topPropellerFrame === 'top3.png' ? 'visible' : 'invisible'" src="/img/top3.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
            <img ref="propellerFrameTop4" :class="topPropellerFrame === 'top4.png' ? 'visible' : 'invisible'" src="/img/top4.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
            <img ref="propellerFrameTop5" :class="topPropellerFrame === 'top5.png' ? 'visible' : 'invisible'" src="/img/top5.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
            <img ref="propellerFrameTop6" :class="topPropellerFrame === 'top6.png' ? 'visible' : 'invisible'" src="/img/top6.png" class="transition-all duration-[100ms] absolute z-30 top-0 w-[1620px] h-[1620px] left-0" />
            <img ref="propellerFrameBack0" :class="backPropellerFrame === 'back0.png' ? 'visible' : 'invisible'" src="/img/back0.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
            <img ref="propellerFrameBack1" :class="backPropellerFrame === 'back1.png' ? 'visible' : 'invisible'" src="/img/back1.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
            <img ref="propellerFrameBack2" :class="backPropellerFrame === 'back2.png' ? 'visible' : 'invisible'" src="/img/back2.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
            <img ref="propellerFrameBack3" :class="backPropellerFrame === 'back3.png' ? 'visible' : 'invisible'" src="/img/back3.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
            <img ref="propellerFrameBack4" :class="backPropellerFrame === 'back4.png' ? 'visible' : 'invisible'" src="/img/back4.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
            <img ref="propellerFrameBack5" :class="backPropellerFrame === 'back5.png' ? 'visible' : 'invisible'" src="/img/back5.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
            <img ref="propellerFrameBack6" :class="backPropellerFrame === 'back6.png' ? 'visible' : 'invisible'" src="/img/back6.png" class="transition-all duration-[100ms] absolute z-10 top-0 w-[1620px] h-[1620px] left-[10px]" />
            <img ref="propeller" src="/img/propeller.png" class="transition-all duration-[100ms] absolute z-20 top-[0px] w-[1620px] h-[1620px] left-[0px]" />
            <img ref="bond" src="/img/bond.png" class="transition-all duration-[100ms] absolute z-20 top-[0px] w-[1620px] h-[1620px] left-[0px]" :style="`transform: rotate(${bodyAngle}rad)`" />
            <img ref="nellie" src="/img/nellie.png" class="transition-all duration-[100ms] absolute z-50 top-[0px] w-[1620px] h-[1620px] left-[0px]" />
            <img ref="bg" src="/img/background.png" class="absolute top-0 left-[-280px] h-[1280px] w-[1280px] hidden" />
            <canvas ref="initialFaceCanvas" v-if="app.showInitialFace" class="transition-all duration-[100ms] absolute z-[60] top-[37%] left-1/2 -translate-x-[45%] -scale-x-100" width="200" height="200" />
            <canvas ref="initialHelmetCanvas" v-if="app.showInitialFace" class="transition-all duration-[100ms] absolute z-[70] top-[39%] left-1/2" width="200" height="200" :style="`transform: translateX(-45%) rotate(${headAngle - Math.PI}rad) scaleX(-1)`"/>
        </div>
    </template>

    <video ref="bgVideo" src="/video/background.mp4" muted playsinline loop class="transition-opacity duration-500 absolute z-10 h-full w-full mt-0" :class="userIsActive && !awaitingPhotoConfirmation && nellieInFrontOfHills ? 'opacity-100' : 'opacity-0'"/>

    <Transition
        enter-active-class="transition-opacity duration-300 ease-out-in"
        leave-active-class="transition-opacity duration-75 ease-out-in"
        enter-from-class="opacity-0"
        leave-to-class="opacity-0"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
    >
        <PhotoMessage v-if="showPhotoMessage && userIsActive && user.has_good_keypoint_score && !app.gsapPlaying" :gesture="currentGesture" :gesture-in-progress="gestureInProgress" @userAction="setUserAction" />
    </Transition>

    <!-- VIEW: Countdown Overlay  -->
    <Vue3Lottie v-if="showCountDown" :animationData="CountDown" :loop="1" height="720" width="1280" class="relative z-50" @onComplete="takePhoto"/>

    <canvas id="photo" ref="photo" v-show="showPhoto" class="absolute z-50" width="720" height="1280" :style="`transform: scaleX(-1)`" />
    <ConfirmPhotoMessage v-if="showPhoto && app.state === 'view_photo' || app.state === 'view_photo'" :gesture="currentGesture" :gesture-in-progress="gestureInProgress" @userAction="setUserAction"/>

    <!-- VIEW: Download -->
    <DownloadState v-if="isDownload || app.state === 'download_photo'"  :blob-data="app.photo"  @complete="downloadCompleted" />
    <!-- Copy Right Message -->
    <CopyRightMessage v-show="app.state !== 'view_photo'" />
</template>

<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
import CameraUnavailable from "@/components/CameraUnavailable.vue";
import IdleState from "@/components/IdleState.vue";
import ReadyState from "@/components/ReadyState.vue";
import PhotoMessage from "@/components/PhotoMessage.vue";
import ConfirmPhotoMessage from "@/components/ConfirmPhotoMessage.vue";
import DownloadState from "@/components/DownloadState.vue";
import {createSegmenter, initHands, nelliePropeller, nellieSway, renderSegmentation} from "@/js/helpers";
import {GestureEstimator} from "@/js/fingerpose/fingerPose.js";
import {createScene, renderScene, setXRotation, setYRotation} from "@/js/helpers/three.js";
import CountDown from "@/assets/countdown.json";
import CopyRightMessage from "@/components/CopyRightMessage.vue";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import { CustomEase } from "gsap/CustomEase.js";
import { CustomWiggle } from "gsap/CustomWiggle.js";
import useAnalytics from "@/composables/useAnalytics";

gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(CustomWiggle);

const nellieContainer = ref(null);
const bgVideo = ref(null);

const gestureEstimator = new GestureEstimator();

const canvas = ref(null);
const ctx = ref(null);

const photo = ref(null);
const photoCtx = ref(null);

const videoFeed = ref(null);
const cameraUnavailable = ref(true);
const idleCounter = ref(0);
const awaitingGesture = ref(false);
const isDownload = ref(false);
const bg = ref(null);
const logoDark = ref(null);

const readyToDetect = ref(false);

// list of all video devices
let cameras = [];
let currentCameraId = null;

let segmenter;
let canvasWidth = ref(720);
let canvasHeight = ref(900);
const photoHeight = 1280;
const photoWidth = 720;
let photoCanvasFont = new FontFace('Gotham', "url('/fonts/Gotham-Light.otf')");
let photoCanvasFontDigital = new FontFace('Digital', "url('/fonts/digital-7.ttf')");
let photoCanvasFontSohne = new FontFace('SohneSchmal', "url('/fonts/SohneSchmal-Dreiviertelfett.otf')");
let photoCanvasFontHeldane = new FontFace('TestHeldane', "url('/fonts/TestHeldaneText-Regular.otf')");
let photoCanvasFontHeldaneBold = new FontFace('TestHeldaneBold', "url('/fonts/TestHeldaneText-Bold.otf')");
let photoCanvasFontLoaded = false;
let nellieWidth = 520;

let animationFrame = null;

const currentGesture = ref(null);
const gestureInProgress = ref(false);
const resetGesture = () => {
    awaitingGesture.value = false;
    currentGesture.value = null;
}

const hands = initHands();
let handsInitialised = false;
let handsInterval = null;

const swayDirection = ref('top');
const swayEnd = ref(14);
const swayStartTime = ref(null);
const swayActive = ref(true);
const swayDuration = ref(1000);

const propellerStartTime = ref(null);
const topPropellerFrame = ref('top0.png');
const backPropellerFrame = ref('back0.png');

const propellerFrameTop0 = ref(null);
const propellerFrameTop1 = ref(null);
const propellerFrameTop2 = ref(null);
const propellerFrameTop3 = ref(null);
const propellerFrameTop4 = ref(null);
const propellerFrameTop5 = ref(null);
const propellerFrameTop6 = ref(null);

const propellerFrameBack0 = ref(null);
const propellerFrameBack1 = ref(null);
const propellerFrameBack2 = ref(null);
const propellerFrameBack3 = ref(null);
const propellerFrameBack4 = ref(null);
const propellerFrameBack5 = ref(null);
const propellerFrameBack6 = ref(null);

const nellieOffsetY = ref(swayEnd.value / 2);

const bodyAngle = ref(0);
const headAngle = ref(0);
const headRotationOrigin = ref(50);

const showPhotoMessage = ref(false);
const showCountDown = ref(false);
const showPhoto = ref(false);

const awaitingPhotoConfirmation = ref(false);

const propellerFrameTop = ref(null);
const propellerFrameBack = ref(null);
const propeller = ref(null);
const nellie = ref(null);
const helmet = ref(null);
const helmet_inverse = ref(null);
const helmetX = ref(0);
const helmetY = ref(0);
const shoulderMidpoint = ref(0);
const bond = ref(null);

let res = null;
const threeCanvas = ref(null);
const initialFaceCanvas = ref(null);
const initialHelmetCanvas = ref(null);

const nellieX = ref(370);
const nellieY = ref(870);

const user = ref({
    has_good_keypoint_score: false,
    is_in_view: false,
    is_over_18: false,
    confirmed_age: false,
});

const userIsActive = computed(() => {
    if (app.value.state === 'active') {
        return true;
    }
    return user.value.is_in_view && user.value.has_good_keypoint_score && user.value.is_over_18;
});

const resetUserState = () => {
    user.value.is_in_view = false;
    user.value.is_over_18 = false;
    user.value.confirmed_age = false;
    user.value.has_good_keypoint_score = false;
}

let initialFacePhoto = null;
let initialFacePhotoRatio = null;
let threeImageData = null;

// idle,
// ready_to_detect, user_nearly_in_view,
// legal_age_check,
// active,
// view_photo, 
// download_photo
const app = ref({
    state: 'idle',
    isIdle: true,
    gsapPlaying: false,
    initialFaceRendered: false,
    showInitialFace: true,
    photo: null
});

const nellieInFrontOfHills = ref(false);

const timeouts = {
    introAnimation: 4000,
    askForPhoto: 15000,
    underAgeReset: 3500,
    retakePhoto: 1000
}

const {
    captureActivated,
    captureHappyWithPhoto,
    captureNotOver18,
    captureOver18,
    captureTakePhoto,
    captureUnhappyWithPhoto,
} = useAnalytics();

/**
 * Get all available video devices
 * Handles the current video device being made unavailable / available again
 */
const updateDeviceList = () => {
    cameras = [];
    navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
            devices.forEach((device) => {
                if (device.kind === "videoinput") {
                    cameras.push(device.deviceId);
                }
            });

            if (currentCameraId !== null && !cameras.includes(currentCameraId)) {
                cameraUnavailable.value = true;
            }

            if (currentCameraId !== null && cameraUnavailable.value) {
                startVideoStream();
            }
        });
}

const startVideoStream = () => {
    navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
            // width: 1440,
            // height: 1080
            width: 1920,
            height: 1440
        }
    })
    .then(stream => {
        stream.getTracks().forEach((track) => {
            currentCameraId = track.getSettings().deviceId;
        });
        videoFeed.value.srcObject = stream;
        videoFeed.value.play();
        cameraUnavailable.value = false;
    })
    .catch(err => {
        cameraUnavailable.value = true;
        console.log(`Following error occured: ${err}`);
    });
}

const addEventListeners = () => {
    navigator.mediaDevices.ondevicechange = (event) => {
        updateDeviceList();
    };
}

const onFrame = async () => {
    if (!app.value.gsapPlaying || initialFacePhoto === null) {

        res = await renderSegmentation(segmenter, videoFeed.value, canvasWidth.value, canvasHeight.value, ctx.value, initialFacePhoto === null ? true : userIsActive.value, nellieWidth, nellieX.value, nellieY.value, app.value.state);

        if (app.value.gsapPlaying && initialFacePhoto === null && res.blurredMask) {
            initialFacePhoto = res.blurredMask;
            initialFacePhotoRatio = res.headRatio;

            let threeCtx = threeCanvas.value.getContext('webgl2');
            let threePixels = new Uint8Array(threeCtx.drawingBufferWidth * threeCtx.drawingBufferHeight * 4);
            threeCtx.readPixels(0, 0, threeCtx.drawingBufferWidth, threeCtx.drawingBufferHeight, threeCtx.RGBA, threeCtx.UNSIGNED_BYTE, threePixels);
            threeImageData = new ImageData(Uint8ClampedArray.from(threePixels),threeCtx.drawingBufferWidth,threeCtx.drawingBufferHeight);
        }
        
        if (app.value.showInitialFace && !app.value.gsapPlaying && app.value.state === 'active') {
            app.value.showInitialFace = false;
        }

        const userNotInView = res.state === 0;
        const userPossiblyDetected = res.state === 1;
        const userDetected = res.state === 2;

        // reset user detection state
        user.value.is_in_view = false;
        user.value.has_good_keypoint_score = false;

        if (userNotInView || (app.value.state === 'active' && userPossiblyDetected)) {
            idleCounter.value++;
        } else {
            idleCounter.value = 0;
        }

        if (userPossiblyDetected) {
            user.value.is_in_view = true;
        }

        if (userDetected) {
            user.value.has_good_keypoint_score = true;
            user.value.is_in_view = true;

            if (res.head) {
                setYRotation((Math.PI / 2) * (res.head.yaw * 0.6));
                setXRotation(res.head.pitch);
                renderScene();
            }
        }

        /**
         * Nellie sway
         */
        if (swayStartTime.value == null) swayStartTime.value = Date.now();
        let sway = nellieSway(swayDirection.value, swayEnd.value, swayStartTime.value, swayActive.value, swayDuration.value);
        if (sway.active) {
            nellieOffsetY.value = sway.offset;
        } else {
            swayDirection.value = sway.direction;
        }

        if (userDetected && userIsActive.value) {
            headAngle.value = -res?.head?.roll * 1.4;
            headRotationOrigin.value = headAngle.value < 0 ? 50 : 55;
            bodyAngle.value = -res.bodyAngle;
            helmetX.value = res.helmetX;
            helmetY.value = res.helmetY;
            shoulderMidpoint.value = res.shoulderMidpoint;
        }

    } else {
        if (initialFacePhoto !== null && app.value.initialFaceRendered === false && initialFaceCanvas.value) {
            let faceCtx = initialFaceCanvas.value.getContext('2d', { willReadFrequently: true })
            faceCtx.drawImage(initialFacePhoto, 0, 0, initialFacePhoto.width, initialFacePhoto.height, 100 - ((initialFacePhoto.width * initialFacePhotoRatio) / 2), 200 - (initialFacePhoto.height * initialFacePhotoRatio), initialFacePhoto.width * initialFacePhotoRatio, initialFacePhoto.height * initialFacePhotoRatio);

            let initialHelmetCtx = initialHelmetCanvas.value.getContext('2d', { willReadFrequently: true })
            initialHelmetCtx.putImageData(threeImageData, 0, 0);
            app.value.initialFaceRendered = true;
        }
    }

    if (propellerStartTime.value == null) propellerStartTime.value = Date.now();
    let propeller = nelliePropeller(propellerStartTime.value);
    topPropellerFrame.value = propeller.topFrame;
    backPropellerFrame.value = propeller.backFrame;
    if (propeller.reset) {
        propellerStartTime.value = null;
    }

    animationFrame = requestAnimationFrame(onFrame);
}

const onHandsResults = (results) => {
    if (!results.multiHandedness.length) {
        gestureInProgress.value = false;
        return null;
    }

    const estimatedGesture = gestureEstimator.estimate([results.multiHandLandmarks?.[0], results.multiHandLandmarks?.[1]], {
        'thumbs_up': 9.5,
        'fingers_splayed': 9.5,
        'thumbs_down': 8
    });

    if (estimatedGesture.gestureFound) {
        if (currentGesture.value === estimatedGesture.gestureFound) {
            (currentGesture.value) ? gestureInProgress.value = true : gestureInProgress.value = false;
        } else {
            currentGesture.value = estimatedGesture.gestureFound;
        }
    } else {
        gestureInProgress.value = false;
    }
}

const segment = async (data) => {
    segmenter = await createSegmenter();
    if (!handsInitialised) {
        await hands.send({image: videoFeed.value});
        handsInitialised = true;
    }
    animationFrame = requestAnimationFrame(onFrame);
}

const introAnimation = () => {
    app.value.gsapPlaying = true;
    //cancelAnimationFrame(animationFrame);

    setTimeout(() => {
        nellieInFrontOfHills.value = true;
    }, timeouts.introAnimation)

    setTimeout(() => {
        bgVideo.value.play();
    }, 6000)

    gsap.set(nellieContainer.value, {
        scale: 0.05,
        //opacity: 0,
    });

    let enterDuration = 7;

    CustomWiggle.create("enterWiggle", {wiggles: 3});
    CustomEase.create("scale", "M0,0,C0.009,0.028,0.188,0.011,0.208,0.026,0.358,0.136,0.412,0.078,0.5,0.2,0.574,0.302,0.757,0.5,0.792,0.712,0.811,0.832,0.938,1,1,1");
    CustomEase.create("enterIntro", "M0,0,C0.037,0.073,0.084,0.244,0.184,0.402,0.185,0.403,0.284,0.46,0.454,0.526,0.462,0.529,0.741,0.708,0.84,0.92,0.878,1.002,0.924,0.986,1,1");

    let tl = gsap.timeline({ defaults: { ease: "none" } });
    tl.to(nellieContainer.value, {
            duration: enterDuration,
            motionPath: {
                path: "M 324 1447 C 285 402 297 391 389 409 C 414 417 408 458 385 455 C 339 457 339 499 358 501 C 412 516 319 999 370 870",
            },
            ease: 'enterIntro',
        }, 0)
    tl.to(nellieContainer.value,{ duration: enterDuration, rotation: -15, ease: 'enterWiggle' }, 0)
    // tl.to(nellieContainer.value, { duration: 5, opacity: 1 }, 0)

    gsap.to(nellieContainer.value, {
        scale: 1,
        duration: enterDuration,
        ease: 'scale',
        onComplete: introComplete,
    });

    nellieX.value = 370;

}

const introComplete = () => {
    app.value.gsapPlaying = false;
}

const setUserAction = (e) => {

    let offscreen;
    let ctx;

    console.log(e);

    switch (e) {
        case 'is_over_18':
            user.value.is_over_18 = true;
            user.value.confirmed_age = true;
            app.value.state = 'active';

            nellieX.value = - 2000;

            captureOver18();
            nextTick(() => {
                introAnimation();
            })
            break;
        case 'not_over_18':
            user.value.is_over_18 = false;
            user.value.confirmed_age = true;
            captureNotOver18();
            resetGesture();

            setTimeout(() => {
                resetState();

            }, timeouts.underAgeReset)
            break;
        case 'take_photo':
            captureTakePhoto();
            resetGesture();
            showPhotoMessage.value = false;
            showCountDown.value = true;
            //hideThreeCanvas.value = true;
            break;
        case 'happy_with_photo':
            captureHappyWithPhoto();
            resetGesture();

            offscreen = new OffscreenCanvas(photoWidth, photoHeight);
            ctx = offscreen.getContext('2d');

            // draw the canvas but flipped
            ctx.scale(-1, 1); // flip
            ctx.drawImage(photo.value, 0,0, -1 * photoWidth, photoHeight);
            ctx.scale(-1, 1); // flip back

            offscreen.convertToBlob().then(blob => {
                app.value.photo = blob;
                isDownload.value = true;
                app.value.state = 'download_photo';
            })
            break;
        case 'unhappy_with_photo':
            captureUnhappyWithPhoto();
            resetGesture();
            showPhoto.value = false;
            awaitingPhotoConfirmation.value = false;

            app.value.state = 'active';
            break;
    }

    gestureInProgress.value = false;
}

const takePhoto = () => {
    showCountDown.value = false;
    awaitingPhotoConfirmation.value = true;

    const logoTop = 16 * 5.8;
    const logoWidth = 210;
    const logoHeight = 161;
    const logoLeft = (logoWidth - photoCtx.value.canvas.width) / 2;

    // clear
    photoCtx.value.clearRect(0,0, photoWidth, photoHeight);

    // draw background
    photoCtx.value.scale(-1, 1); // flip
    photoCtx.value.drawImage(bg.value, 0, 0, -1 * photoWidth, photoHeight);
    photoCtx.value.scale(-1, 1); // flip back

    // center?
    photoCtx.value.translate(photo.value.width / 2, photo.value.height / 2);
    photoCtx.value.rotate(-bodyAngle.value); // ?
    photoCtx.value.scale(-1, 1); // flip
    photoCtx.value.drawImage(propeller.value, (-photo.value.width / 2) - 460, -photo.value.height / 2 - 120 - nellieOffsetY.value, 1620, 1620);
    photoCtx.value.drawImage(propellerFrameBack6.value, (-photo.value.width / 2) - 460, -photo.value.height / 2 - 120 - nellieOffsetY.value, 1620, 1620);
    photoCtx.value.drawImage(propellerFrameTop6.value, (-photo.value.width / 2) - 460, -photo.value.height / 2 - 120 - nellieOffsetY.value, 1620, 1620);
    photoCtx.value.drawImage(bond.value, (-photo.value.width / 2) - 460, -photo.value.height / 2 - 120 - nellieOffsetY.value, 1620, 1620);
    photoCtx.value.scale(-1, 1); // flip bck
    photoCtx.value.rotate(bodyAngle.value);
    photoCtx.value.translate(-photo.value.width / 2, -photo.value.height / 2);

    // draw logo
    photoCtx.value.scale(-1, 1); // flip
    photoCtx.value.drawImage(logoDark.value, logoLeft, logoTop, -1 * logoWidth, logoHeight);
    photoCtx.value.scale(-1, 1); // flip back

    // draw face
    {
        const dx = res.x
        const dy = res.y
        const dWidth = res.blurredMask.width * res.headRatio
        const dHeight = res.blurredMask.height * res.headRatio

        photoCtx.value.drawImage(res.blurredMask, 0,0, res.blurredMask.width, res.blurredMask.height, dx, dy, dWidth, dHeight);
    }

    photoCtx.value.translate(photo.value.width / 2, photo.value.height / 2);
    photoCtx.value.rotate(-bodyAngle.value);
    photoCtx.value.scale(-1, 1); // flip
    photoCtx.value.drawImage(nellie.value, (-photo.value.width / 2) - 460, -photo.value.height / 2 - 120 - nellieOffsetY.value, 1620, 1620);
    photoCtx.value.scale(-1, 1); // flip back
    photoCtx.value.rotate(bodyAngle.value);
    photoCtx.value.translate(-photo.value.width / 2, -photo.value.height / 2);

    showPhoto.value = true;
    app.value.state = 'view_photo'

    renderScene();

    // draw helmet
    let threeCtx = threeCanvas.value.getContext('webgl2');
    let threePixels = new Uint8Array(threeCtx.drawingBufferWidth * threeCtx.drawingBufferHeight * 4);
    threeCtx.readPixels(0, 0, threeCtx.drawingBufferWidth, threeCtx.drawingBufferHeight, threeCtx.RGBA, threeCtx.UNSIGNED_BYTE, threePixels);
    threeImageData = new ImageData(Uint8ClampedArray.from(threePixels),threeCtx.drawingBufferWidth,threeCtx.drawingBufferHeight);

    let tempPutCanvas = document.createElement('canvas');
    tempPutCanvas.width = 200;
    tempPutCanvas.height = 200;
    let tempPutCtx = tempPutCanvas.getContext('2d', { willReadFrequently: true })
    tempPutCtx.putImageData(threeImageData, 0, 0);

    let tempDrawCanvas = document.createElement('canvas');
    tempDrawCanvas.width = 200;
    tempDrawCanvas.height = 200;
    let tempDrawCtx = tempDrawCanvas.getContext('2d', { willReadFrequently: true })
    tempDrawCtx.translate(100, 100);
    tempDrawCtx.rotate(-Math.PI - headAngle.value);
    tempDrawCtx.drawImage(tempPutCanvas, -100, -100);
    tempDrawCtx.rotate(Math.PI + headAngle.value);
    tempDrawCtx.translate(-100, -100);

    photoCtx.value.drawImage(tempDrawCanvas, (canvasWidth.value - helmetX.value) - 100, helmetY.value - 100);

    // draw
    const roundedText = 'Crafted without compromise.';
    const roundedTextBold = 'Please savour The Macallan responsibly.';
    const copyrightText = 'James Bond Indicia © 1962-2023 Danjaq and MGM. All Rights Reserved.';

    photoCtx.value.scale(-1, 1); // flip
    photoCtx.value.font = "20px TestHeldane";
    photoCtx.value.fillStyle = 'white';
    photoCtx.value.fillText(roundedText, -720 + 36 + 24, 1280 - 82 - 14);

    photoCtx.value.font = "800 20px TestHeldaneBold";
    photoCtx.value.fillStyle = 'white';
    photoCtx.value.fillText(roundedTextBold, -720 + 36 + 273, 1280 - 82 - 14);

    photoCtx.value.font = "20px SohneSchmal";
    photoCtx.value.fillStyle = 'white';
    photoCtx.value.fillText(copyrightText, -720 + 172, 1280 - 52 - 6);

    photoCtx.value.scale(-1, 1); // flip back
}

const resetState = () => {
    resetGesture();
    isDownload.value = false;
    showPhotoMessage.value = false;
    showCountDown.value = false;
    showPhoto.value = false;
    awaitingPhotoConfirmation.value = false;

    nellieInFrontOfHills.value = false;

    initialFacePhoto = null;
    initialFacePhotoRatio = null;
    threeImageData = null;

    bgVideo.value.load();

    app.value.state = 'idle';
    app.value.isIdle = true;
    app.value.gsapPlaying = false;
    app.value.initialFaceRendered = false;
    app.value.showInitialFace = true;
    app.value.photo = null;

    bodyAngle.value = 0;
    headAngle.value = 0;

    resetUserState();

    ctx.value.clearRect(0, 0, canvasWidth.value, canvasHeight.value);
    photoCtx.value.clearRect(0, 0, photoWidth, photoHeight);

    cancelAnimationFrame(animationFrame);
}

const downloadCompleted = () => {
    resetState();
    window.location.reload();
}

onMounted(() => {
    nextTick(() => {
        //canvas.value.height = window.innerHeight;
        //canvas.value.width = window.innerWidth;
    })
    hands.setOptions({
        maxNumHands: 2,
        modelComplexity: 1,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
    });
    hands.onResults(onHandsResults);

    ctx.value = canvas.value.getContext('2d', { willReadFrequently: true });
    photoCtx.value = photo.value.getContext('2d');

    addEventListeners();
    updateDeviceList();
    startVideoStream();
    createScene(threeCanvas.value);

    photoCanvasFont.load().then(() => {
        photoCanvasFontLoaded = true;
    })

    photoCanvasFontDigital.load();
    photoCanvasFontSohne.load();
    photoCanvasFontHeldane.load();
})

watch(cameraUnavailable, (newVal) => {
    if (!newVal && app.value.state === 'ready_to_detect') {
        segment('camera');
    } else {
        cancelAnimationFrame(animationFrame);
    }
});


watch(() => app.value.state, (newVal, oldVal) => {
    console.log('state changed from', oldVal, newVal);

    if (newVal === 'idle') {
        app.value.isIdle = true;
        resetState();
    }

    if (newVal === 'ready_to_detect' && !cameraUnavailable.value) {
        segment('ready');
    }

    if (newVal === 'legal_age_check' && !cameraUnavailable.value) {
        app.value.isIdle = false;
        awaitingGesture.value = true;


        captureActivated();
    }

    if (oldVal === 'legal_age_check') {
        // age confirmed
        resetGesture();
    }

    if (newVal === 'active' && oldVal !== 'view_photo') {
        setTimeout(() => {
            showPhotoMessage.value = true;
            awaitingGesture.value = true;
        }, timeouts.askForPhoto)
    }

    if (newVal === 'active' && oldVal === 'view_photo') {
        setTimeout(() => {
            showPhotoMessage.value = true;
            awaitingGesture.value = true;
        }, timeouts.retakePhoto)
    }

    if (newVal === 'view_photo') {
        setTimeout(() => {
            awaitingGesture.value = true;
        }, 1000);
    }

    if (oldVal === 'download_photo') {
        downloadCompleted();
    }
});


watch(currentGesture, () => {
    gestureInProgress.value = false;
});

watch(awaitingGesture, (newVal) => {
    if (newVal) {
        handsInterval = setInterval(async () => {
            if(!app.value.gsapPlaying) {
                await hands.send({image: videoFeed.value})
            }
        }, 50)
    } else {
        clearInterval(handsInterval)
    }
})

watch(idleCounter, (newVal) => {
    if (newVal > 20) {
        if (app.value.isIdle) {
            if (user.value.is_in_view) {
                app.value.isIdle = false;
            }
        } else {
            // user has left
            app.value.isIdle = true;
            resetState();
        }
        idleCounter.value = 0;
    }
});


watch(swayDirection, () => {
    swayStartTime.value = null;
});

watch(isDownload, (newVal) => {
    if (newVal) {
        resetGesture();
        showPhoto.value = false;
    }
});

</script>