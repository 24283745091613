<template>
    <div
        class="z-[60] absolute bottom-[3.25rem] left-1/2 -translate-x-1/2 w-max text-center text-xs text-white font-light">
        <p class="px-4 py-2 font-testHeldane text-xl">Crafted without compromise. <span class="font-bold">Please savour The Macallan responsibly.</span></p>
        <p class="font-sohneSchmal text-xl">James Bond Indicia © 1962-2023 Danjaq and MGM. All Rights Reserved.</p>
    </div>
</template>
<script>
export default {
    name: 'CopyRightMessage'
}
</script>